

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

@media screen and (min-width: 800px) {
  .header-ul{
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .about-section{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
  .ProgressBar{
    margin-bottom: 10px;
  }
  .skills{
    padding-top: 50px;
  }
  .profile-photo{
    max-width: 200px;
    max-height: 200px;
    border-radius: 20%;
  }

  nav ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
  }

  nav li {
    cursor: pointer;
    color: white;
  }

  .section {
    padding: 50px;
    margin: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }


  .App-link {
    color: #61dafb;
  }


  footer {
    background-color: #282c34;
    padding: 20px;
    text-align: center;
  }

  .social-icons a {
    margin: 0 10px;
  }
  .section {
    padding: 50px;
    margin: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  .card-portfolio{
    cursor: pointer;
    max-width:400px;
    margin-left: 33%;
    background-color: #f9f9f9;
  }
}
@media screen and (max-width: 790px) {
  .header-ul{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .profile-photo{
    max-width: 200px;
    max-height: 200px;
    border-radius: 20%;
  }
  nav ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
  }

  nav li {
    cursor: pointer;
    color: white;
  }
  .section {
    padding: 50px;
    margin: 20px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
  }
  .social-icons a {
    margin: 0 10px;
  }

  footer {
    background-color: #282c34;
    padding: 20px;
    text-align: center;
  }
  .ProgressBar{
    margin-bottom: 10px;
  }
  .about-section{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: justify;
  }
}